.App {
  position: relative;
  text-align: center;
  color: white;
  overflow: auto;
  min-height: calc(100% - 4rem);

  display: grid;
  place-items: center;
  padding-top: 2rem;
  padding-bottom: 1rem;
}

@media (min-width: 768px) {
  .App {
    padding-bottom: 2rem;
  }
}

p {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
button {
  margin: 0;
}

.bg_image {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  object-position: right;
  object-fit: cover;
  z-index: -1;
}

.socks_image {
  position: fixed;
  top: 0;
  left: 0;
  transform: translate(1.5rem, 1rem);
  width: clamp(114px, 20vw, 151px);

  @media (min-width: 576px) {
    transform: translate(3rem, 2rem);
  }
}

.egirl_image {
  position: fixed;
  top: 0;
  right: 0;
  width: clamp(90px, 8vw, 180px);
  transform: translate(-1.5rem, 1rem);

  @media (min-width: 576px) {
    transform: translate(-3rem, 2rem);
  }
}
